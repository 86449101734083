import { TYPE_BROADCAST } from './types'
/**
 * Change text only, do not change text inside {{ }}
 */
export const customText = {
  [TYPE_BROADCAST.CUSTOM_ANNOUNCEMENT]: `Yth. Bapak/Ibu/Saudara/i, 
*{{GUEST_NAME}}* 

Mohon klik link website announcement kami dibawah ini sebagai detail pemberitahuan:
{{LINK}}

Bersama rahmat dan berkat Tuhan yang telah menyatukan kami dan tanpa mengurangi rasa hormat, kami berniat ingin mengumumkan kepada Bapak/Ibu/Saudara/i mengenai acara pernikahan kami: 

*{{BOY_NAME}}*
_Putra dari Bapak {{BOY_FATHER_NAME}} dan Ibu {{BOY_MOTHER_NAME}}_
  & 
*{{GIRL_NAME}}* 
_Putri dari Bapak {{GIRL_FATHER_NAME}} dan Ibu {{GIRL_MOTHER_NAME}}_

Acara pernikahan kami akan dilaksanakan pada hari *{{BROADCAST_WEDDING_DAY}}*.

Berkaitan dengan salah satu pemberlakuan protokol kesehatan, yakni pembatasan jumlah tamu, kami memohon maaf jika belum bisa mengundang Bapak/Ibu/Saudara/i untuk hadir secara langsung. 

Meski demikian, di masa pandemi saat ini hal terpenting adalah sama-sama saling menjaga agar kita semua selalu sehat dan senantiasa dalam lindungan Tuhan.

Terima kasih atas segala doa restu yang telah diberikan kepada kami. 
Mohon pengertiannya, terima kasih.

_Kami yang berbahagia,_
*{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga*`,

  [TYPE_BROADCAST.CUSTOM_INVITATION]: `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

*Tanpa mengurangi rasa hormat, mohon klik link dibawah ini untuk mendapatkan informasi lengkap mengenai acara pernikahan kami:*
{{LINK}}

Bersama rahmat dan berkat Tuhan yang telah menyatukan kami, pada hari ini kami berniat ingin mengundang Bapak/Ibu/Saudara/i untuk hadir dan turut memberikan doa secara langsung dalam acara kami: 

*{{BOY_NAME}}*
_Putra dari Bapak {{BOY_FATHER_NAME}} dan Ibu {{BOY_MOTHER_NAME}}_
  & 
*{{GIRL_NAME}}*
_Putri dari Bapak {{GIRL_FATHER_NAME}} dan Ibu {{GIRL_MOTHER_NAME}}_

Acara pernikahan kami akan dilaksanakan pada hari *{{BROADCAST_WEDDING_DAY}}* di {{BROADCAST_WEDDING_LOCATION}}.

Merupakan suatu kehormatan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dan mendoakan secara langsung dalam acara pernikahan kami. 

Mohon pengertiannya untuk segala protokol kesehatan yang berlaku. Semoga kita semua selalu dalam keadaan sehat. 

Sampai jumpa di acara pernikahan kami!

_Kami yang berbahagia,_
*{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga*`,

    [TYPE_BROADCAST.CUSTOM_INVITATION1]: `_*RANDY & KAROLIN WEDDING*_

Yth. Bapak/Ibu/Saudara/i, 
{{GUEST_NAME}}

Perkenalkan, kami dari *Invitato* mewakili keluarga pengantin ingin menyampaikan undangan pernikahan: 
 
*Randy Harpikto* 
Mr. Herry Widjaja Tjemerlang (†)
& Mrs. Teh Pik Cing

*Karolin Berliana*
Mr. Yuyu Gunawan
& Mrs. Erna Lim

Acara pernikahan akan diselenggarakan pada: 
Hari: Minggu, 7 Januari 2024
Waktu: 18.00 WIB
Lokasi: Damai Indah Golf PIK
_Jalan Marina Raya, Kapuk, Kec. Penjaringan, Jakarta Utara_

Silakan *konfirmasi kehadiran Anda* pada link RSVP undangan website di bawah ini:

{{LINK}}

_*Jika link tidak berwarna biru/tidak muncul silakan untuk coba membalas chat ini dengan "YA", atau simpan nomor ini untuk dapat membuka link.*_

Konfirmasi Bapak/Ibu/Saudara/i kami tunggu maksimal sampai tanggal *3 Januari 2024*. Kehadiran Bapak/Ibu/Saudara/i akan menjadi kehormatan besar bagi kami dan keluarga. 

Terima kasih
 
*Kami yang berbahagia,*
Randy, Karolin & keluarga`,

[TYPE_BROADCAST.CUSTOM_INVITATION2]: `_*RANDY & KAROLIN WEDDING*_

Yth. Bapak/Ibu/Saudara/i, 
{{GUEST_NAME}}

Mewakili keluarga *Randy Harpikto & Karolin Berliana*, kami ingin menanyakan kembali terkait konfirmasi kehadiran Bapak/Ibu pada acara pernikahan *Randy & Karolin*.
 
Apakah Bapak/Ibu dapat menghadiri acara pernikahan *Randy & Karolin*? 
 
Mohon segera konfirmasikan kehadiran Bapak/Ibu/Saudara/i  maksimal sampai tanggal *4 Januari 2024.* Dengan mengisi dan mengklik tombol *Submit RSVP* pada link undangan pernikahan di bawah ini:

{{LINK}}

Konfirmasi Bapak/Ibu akan sangat bermanfaat untuk persiapan pernikahan kedua mempelai nantinya. 

Terima kasih ☺️🙏🏻`,
[TYPE_BROADCAST.CUSTOM_INVITATION3]: `Yth. Bapak/Ibu/Saudara/i, 
{{GUEST_NAME}}

Mewakili keluarga pengantin, kami dari Invitato ingin mengingatkan undangan pernikahan *Randy Harpikto & Karolin Berliana.*

Acara pernikahan akan diselenggarakan pada: 
*RESEPSI*
Hari: Minggu, 7 Januari 2024
Waktu: 18.00 WIB
Lokasi: Damai Indah Golf PIK 
Jalan Marina Raya , kapuk , kec. Penjaringan , Jakarta Utara

Dresscode: Semi Formal
_Jangan gunakan gaun putih, jangan menggunakan stiletto (heels lancip)_

Berikut kami kirimkan *QR Code* sebagai tanda masuk saat registrasi di acara dan berlaku untuk *5 orang*.

Terimakasih atas perhatian dan dukungan kehadiran untuk pernikahan Randy & Karolin.

_Kami yang berbahagia,_
*Randy Harpikto*
Putra dari Herry Widjaja Tjemerlang (†) & Teh Pik Cing
*Karolin Berliana*
Putri dari Yuyu Gunawan & Erna Lim`,

  //Reminder RSVP
  [TYPE_BROADCAST.ID_REMINDER_RSVP] : `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

Melanjutkan undangan pernikahan kami, *{{BOY_NAME}}* & *{{GIRL_NAME}}*, kami ingin mengingatkan Bapak/Ibu/Saudara/i untuk segera mengisi formulir konfirmasi kehadiran (RSVP) yang ada pada website undangan kami:
{{LINK}} 

Status kehadiran Bapak/Ibu/Saudara/i akan bermanfaat untuk kami dalam mempersiapkan acara pernikahan mendatang. 

Terima kasih atas perhatiannya.

*Kami yang berbahagia,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga_
`,

[TYPE_BROADCAST.EN_REMINDER_RSVP]: `Dear Mr/Mrs/Ms,  
*{{GUEST_NAME}}*

Following up on our wedding invitation, *{{BOY_NAME}}* & *{{GIRL_NAME}}*, we would like to remind you to immediately fill out the attendance confirmation form (RSVP) on our invitation website: 
{{LINK}}

Your attendance status will be very useful for us in preparing the upcoming wedding.

Thank you for your attention

*Yours truly,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_
`,

[TYPE_BROADCAST.ID_REMINDER_EVENT]: `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

Melalui pesan ini, kami ingin mengingatkan kembali undangan pernikahan kami, *{{BOY_NAME}}* & *{{GIRL_NAME}}* yang diselenggarakan pada: 

Hari: {{BROADCAST_WEDDING_DAY}}
Waktu: {{TIME}} 
Lokasi: {{BROADCAST_WEDDING_LOCATION}}

Besar harapan kami Bapak/Ibu/Saudara/i berkenan menghadiri pernikahan kami.

Terima kasih 

*Kami yang berbahagia,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_

`,
[TYPE_BROADCAST.EN_REMINDER_EVENT]: `Dear Mr/Mrs/Ms,  
*{{GUEST_NAME}}*

Through this message, we would like to remind you of our wedding invitation, *{{BOY_NAME}}* & *{{GIRL_NAME}}* which will be held on:

Date: {{BROADCAST_WEDDING_DAY}}
Time: {{TIME}}
Place: {{BROADCAST_WEDDING_LOCATION}}

We hope that you are willing to attend our wedding.

Thank you for your attention

*Yours truly,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_
`,

[TYPE_BROADCAST.ID_THANKS]: `Yth. Bapak/Ibu/Saudara/i,  
{{GUEST_NAME}} 

Mewakili keluarga pengantin *Randy Harpikto* & *Karolin Berliana*, kami dari *Invitato* ingin mengucapkan terima kasih atas kesediaan Bapak/Ibu/Saudara/i yang sudah hadir dan meramaikan acara pernikahan Randy & Karolin. 

Kehadiran Bapak/Ibu/Saudara/i tentunya telah menjadi kebahagiaan untuk kami & keluarga. Kami juga ingin memohon maaf jika selama berlangsungnya acara terdapat hal-hal yang kurang berkenan. 
 
Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan. 

_Kami yang berbahagia,_
*Randy Harpikto*
Putra dari Herry Widjaja Tjemerlang (†) & Teh Pik Cing
*Karolin Berliana*
Putra dari Yuyu Gunawan & Erna Lim
`,

[TYPE_BROADCAST.EN_THANKS]: `Dear Mr/Mrs/Ms,  
*{{GUEST_NAME}}*

We, *{{BOY_NAME}}* & *{{GIRL_NAME}}* and family would like to thank you for your willingness to attend our wedding.

Your presence has certainly been a joy for us & our family. We also want to apologize if during the event there are things that are not pleasing.
 
Thank you for all the words, prayers, and attention given.

*Yours truly,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_

`,


}